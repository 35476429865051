<template>
  <div class="view-root flex-column">
    <div style="text-align: right;">
      <el-button @click="add" type="primary" size="medium">新增</el-button>
    </div>
    <el-table :data="tableData" style="margin-top: 20px">
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="链接" prop="ppt_url"></el-table-column>
      <el-table-column label="类型" prop="type"></el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="primary" size="small"
            >编辑</el-button
          >
          <el-button @click="view(scope.row)" type="primary" size="small"
            >播放</el-button
          >
          <el-button @click="goAttachments(scope.row)" type="primary" size="small"
            >附件列表</el-button
          >
          <el-button @click="remove(scope.row)" type="danger" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="编辑文件信息"
      :visible.sync="showAdd"
      :close-on-click-modal="false"
      :append-to-body="false"
    >
      <el-form class="myEditForm">
        <el-form-item label="名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="文件地址">
          <el-input v-model="form.ppt_url" disabled></el-input>
          <el-upload
            action="/"
            :show-file-list="false"
            :before-upload="(file) => beforeAvatarUpload(file)"
          >
            <el-button size="small" type="primary" :loading="loading"
              >点击上传</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="form.type">
            <el-option value="PDF"></el-option>
            <el-option value="PPT"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button type="primary" size="medium" @click="confirm"
          >确认</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

    <script>
import * as qiniu from "qiniu-js";
import {
getQiniuToken,
getSessionPPTs,
removeSessionPPT,
updateSessionPPT,
} from "../../api/api";
export default {
  data() {
    return {
      tableData: [],
      form: {},
      showAdd: false,
      id: "",
      loading: false,
      session_id: this.$route.query.session_id,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getSessionPPTs({
        session_id: this.session_id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.data;
        }
      });
    },
    add() {
      this.showAdd = true;
      this.form = {
        name: "",
        ppt_url: "",
        type: "",
      };
      this.id = "";
    },
    edit(data) {
      this.form = {
        name: data.name,
        ppt_url: data.ppt_url,
        type: data.type,
      };
      this.id = data._id.$id;
      this.showAdd = true;
    },
    confirm() {
      const loading = this.$loading({
        lock: true,
        text: "文件上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      updateSessionPPT({
        id: this.id,
        session_id: this.session_id,
        ...this.form,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success("更新成功");
            this.showAdd = false;
            this.fetchData();
          }
        })
        .finally(() => {
          loading.close();
        });
    },
    remove(data) {
      this.$confirm("是否确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        removeSessionPPT({ id: data._id.$id }).then((res) => {
          if (res.data.code === 0) {
            this.$message.success("删除成功");
            this.fetchData();
          }
        });
      });
    },
    async beforeAvatarUpload(file) {
      this.loading = true
      let fileName = file.name.split(".")[0];
      this.form.name = fileName;
      let timestamp = new Date().getTime();
      this.form.file_name = timestamp;
      const result = await getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(file, timestamp+'.'+file.name.split(".")[1], token);
        const observer = {
          complete: (res) => {
            this.form.ppt_url =
              "https://mainbucket.reachable-edu.com/" + res.key;
              this.loading = false
          },
        };
        this.observable.subscribe(observer);
      }
    },
    goAttachments(data){

      this.$router.push({
        path: "/meeting/pptAttachments",
        query: {
          id: data._id.$id,
          session_id: this.session_id,
        },
      });

    },
    view(data) {
      this.$router.push({
        path: "/session/pdf",
        query: {
          id: data._id.$id,
          session_id: this.session_id,
        },
      });
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px !important;
  text-align: center;
}
.avatar-uploader-icon1 {
  font-size: 28px;
  color: #8c939d;
  width: 50px;
  height: 50px;
  line-height: 50px !important;
  text-align: center;
}
.avatar {
  width: 140px;
  height: 140px;
  display: block;
}
</style>
